<template>
  <CRow>
    <CCol md="6" xs="12" class="add-policy-form">
      <CCard>
        <CCardBody v-if="isLoading">
          <vcl-list></vcl-list>
        </CCardBody>

        <CCardBody v-if="!isLoading">
          <div class="label">
            <label>{{ $t('ADD_AFFILIATE_POLICY_LABEL_TITLE') }}</label>
          </div>
          <div class="wrap-form">
            <AddMembershipPolicy v-if="policy.type === 'MEMBERSHIP'" :policy="policy" :options="options" :currencyList="currencyList" />

            <AddAffiliatePolicy v-if="policy.type === 'AFFILIATE'" :policy="policy" :options="options" :currencyList="currencyList" />

            <AddMembershipAffiliatePolicy
              v-if="policy.type === 'MEMBERSHIP_AFFILIATE'"
              :policy="policy"
              :options="options"
              :currencyList="currencyList"
            />
          </div>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import AddMembershipPolicy from '@/components/AffiliatePolicies/AddMembershipPolicy';
import AddAffiliatePolicy from '@/components/AffiliatePolicies/AddAffiliatePolicy';
import AddMembershipAffiliatePolicy from '@/components/AffiliatePolicies/AddMembershipAffiliatePolicy';
import endpoints from '@/constants/endpoints';
import responseHelper from '@/utils/response-helper';
import { mapState, mapActions, mapGetters } from 'vuex';
import { env } from '@/constants';
import { VclList } from 'vue-content-loading';
import stringHelper from '@/utils/string-helper';

export default {
  name: 'AddPolicy',
  components: {
    AddMembershipPolicy,
    AddAffiliatePolicy,
    AddMembershipAffiliatePolicy,
    VclList,
  },
  data() {
    return {
      policy: {
        name: '',
        type: 'MEMBERSHIP',
        currencySymbol: '',
        proportionShare: null,
        description: '',
        maxLevels: env.policy.membershipAffiliateMaxLevels,
        rates: [0],
        membershipType: [],
      },
      isLoading: true,
      options: [],
      currencyList: [],
    };
  },
  computed: {
    ...mapActions(['getMembershipType']),
  },
  async mounted() {
    await Promise.all([this.getPolicyTypes(), this.getStakingCurrencyList()]);

    this.isLoading = false;
  },
  methods: {
    async getPolicyTypes() {
      try {
        const res = await this.$http.get(endpoints.getAffiliatePolicyTypes);
        this.options = stringHelper.translateTextForDropdownList(res.data, 'POLICY_TYPE');
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('AFFILIATE_POLICIES_NOTIFY_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    async getStakingCurrencyList() {
      try {
        const result = await this.$http.get(endpoints.getStakingCurrencyList);
        this.currencyList = result.data.map(item => {
          return {
            ...item,
            value: item.currency_symbol,
            label: item.name,
          };
        });
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('AFFILIATE_POLICIES_NOTIFY_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
  },
};
</script>

<style lang="scss">
.add-policy-form {
  .label {
    label {
      color: #657187;
      font-size: 18px;
      font-weight: 600;
      line-height: 34px;
      margin-bottom: 40px;
    }
  }
  .wrap-form {
    .form-group {
      display: flex;
      flex: 0 0 100%;
      width: 100%;
      margin-bottom: 0;
      label {
        width: 35%;
        font-size: 14px;
        font-weight: 300;
        line-height: 32px;
        text-align: right;
        color: #3a4a7f;
        padding-right: 10px;
      }
      select,
      input {
        width: 65%;
        font-size: 16px;
        font-weight: 500;
        color: #3a4a7f;
      }
      &.mb-30 {
        margin-bottom: 30px;
      }
    }
    .group-err-msg {
      width: 65%;
      margin-left: 35%;
      margin-bottom: 15px;
      .error-msg {
        font-size: 14px;
        font-weight: 300;
        color: #e40f0f;
      }
    }
    .field-membership-rate {
      .group-err-msg {
        width: 100%;
        margin-left: 0;
      }
    }
    .btn-policy-submit {
      display: block;
      font-size: 14px;
      font-weight: 600;
      color: #fff;
      background: #0d0da7;
      padding: 5px 25px;
      outline: none;
      box-shadow: none;
      margin: 0 auto;
    }
  }
}
</style>
